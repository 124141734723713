<template>
  <div id="client-manual-setting">
    <b-card v-if="innerWidth <= mobileWidth" class="mb-3 sidebar-mini"
            :class="{'sidebar-mini-shadow':innerWidth <= mobileWidth && is_show_sidebar}">
      <b-row align-h="between" align-v="center">
        <b-col>
          <div class="title-list m-0">
            <div>{{ $t('common.user_manual') }}</div>
          </div>
        </b-col>
        <b-col cols="auto">
          <div v-if="!is_show_sidebar" @click="showSidebar">
            <i class="mdi mdi-menu hover-icon action-button mr-3"/>
          </div>
          <div v-else @click="showSidebar">
            <i class="fas fa-times hover-icon action-button mr-3"/>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="innerWidth <= mobileWidth && is_show_sidebar" class="sidebar-mini-bar">
      <div v-for="item in module_list">
        <div class="list-menu-mini" @click="selectTopic(item._id)" :class="{'active-topic': active_topic === item._id}">
          <div>{{ checkNameTopic(item) }}</div>
        </div>
        <div class="line"/>
      </div>
    </b-card>
    <b-card class="user-manual">
      <div class="sidebar" v-if="innerWidth > mobileWidth">
        <div class="title-list">
          <div>{{ $t('common.user_manual') }}</div>
        </div>
        <div class="line"/>
        <div class="scroll-bar-box">
          <div v-for="item in module_list">
            <div class="list-menu" @click="selectTopic(item._id)" :class="{'active-topic': active_topic === item._id}">
              <div>{{ checkNameTopic(item) }}</div>
            </div>
            <div class="line"/>
          </div>
        </div>
      </div>
      <div
          :class="[{'content-full-box' : innerWidth <= mobileWidth}, 'content', {'content-emp':content_list.length > 0}]"
          id="content-user-manual">
        <div v-if="content_list.length > 0">
          <b-overlay :show="is_loading_select_topic"
                     rounded="sm"
                     opacity="0.85"
                     blur="2px">
            <!--              <div class="title">-->
            <!--                <div class="ml-2">-->
            <!--                  {{ changeNameTopicContent(content.name) }}-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="m-3" v-html="changeWordingContent(content?.content)"/>-->
            <div v-for="(content, index) in content_list" :key="index">
              <div v-b-toggle="`collapse-${content._id}`" class="title"
                   :id="`title-collapse-${content._id}`"
                   @click="actionCaret(content._id)"
              >
                {{ changeNameTopicContent(content.name) }}
                <span class="caret">
                  <div class="ab-center default-icon">
                     <span :style="iconMaskURL('primary-caret')"
                           class="default-caret"
                           :class="caretAnimation(toggleEachTag[`collapse_${content._id}`])"
                           style="z-index: 3; width: 100%; height: 100%"
                     ></span>
                  </div>
                  <span
                      :style="iconMaskURL('circle-caret')" class="default-icon ab-center"
                      style="width: 24px; height: 24px"></span>
                </span>
              </div>
              <b-collapse :id="`collapse-${content._id}`" class="collapse-container" v-if="renderContent" visible>
                <div v-html="changeWordingContent(content?.content)"/>
                <b-button class="collapse-container__bottom-right" variant="success"
                          v-if="innerWidth > mobileWidth"
                          @click="scrollToTop(content._id)"
                          style="display: none"
                          :id="`bottom-up-${content._id}`"
                ><img
                    src="@/assets/images/icon/arrow-up.svg" class="arrow-icon"/></b-button>
              </b-collapse>
            </div>
          </b-overlay>
        </div>
        <div v-else class="text-center">
          <img :src="require('@/assets/images/icon/empty-folder.svg')" class="empty-file-icon" alt="empty-table"/>
          <div class="mt-2">
            {{ $t('common.no_data') }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {mapState} from "vuex";
import userManualApi from "@/repository/userManualApi";
import OverlayPage from "@/components/common/overlayPage.vue";
import functionsCommon from "@/common/functions";

export default {
  name: "index",
  components: {OverlayPage},
  data() {
    return {
      module_list: [
        {
          id: 1,
          topic: 'การสมัครสมาชิก / เข้าสู่ระบบ'
        },
        {
          id: 2,
          topic: 'การทดลองใช้ฟรี'
        }
      ],
      renderContent: true, //render content when changing content, the cause comes through b-collapse that was not re-render index, it must use v-if to break them out and then re-create the element.
      is_show_sidebar: false,
      active_topic: null,
      content_list: [],
      toggleEachTag: {},
      idEachTag: [],
      is_loading_select_topic: true
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    })
  },
  mounted() {
    this.getModuleList()
  },
  updated() {
    const target = document.getElementById(`content-user-manual`)
    target.addEventListener('scroll', this.allowScrollToTop)
  },
  destroyed() {
    const target = document.getElementById(`content-user-manual`)
    target.removeEventListener('scroll', this.allowScrollToTop)
  },
  methods: {
    caretAnimation(id) {
      if (!id && !_.isUndefined(id)) {
        return 'normal-caret'
      }
    },
    iconMaskURL(name) {
      return functionsCommon.iconMaskURL(name)
    },
    scrollToTop(id) {
      const target = document.getElementById(`title-collapse-${id}`)
      if (!_.isNull(target)) {
        target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
      }
    },
    displayButtonScroll(item) {
      const target = document.getElementById(`title-collapse-${item}`)
      let button = document.getElementById(`bottom-up-${item}`)
      let content = document.getElementById(`collapse-${item}`)
      if (!this.isInViewport(target)) {
        button.style.display = 'block'
        content.style.paddingBottom = '34px'
        content.style.marginBottom = '22px'
      } else {
        button.style.display = 'none'
        content.style.paddingBottom = '0px'
        content.style.marginBottom = '16px'
      }
    },
    allowScrollToTop() {
      if (this.idEachTag.length > 0) {
        this.idEachTag.forEach((item) => {
          this.displayButtonScroll(item)
        })
      }
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    actionCaret(id) {
      this.toggleEachTag = {
        ...this.toggleEachTag,
        [`collapse_${id}`]: !this.toggleEachTag[`collapse_${id}`]
      }
      setTimeout(() => { //Wait for action close collapse has done
        this.allowScrollToTop()
      }, 500)
    },
    showSidebar(e) {
      this.is_show_sidebar = !this.is_show_sidebar
    },
    getModuleList() {
      userManualApi.getListUserManualModule().then(resp => {
        this.active_topic = resp.length > 0 ? resp[0]._id : null
        this.selectTopic(this.active_topic)
        this.module_list = resp
      }).catch(err => {
        throw err
      })
    },
    selectTopic(id) {
      this.active_topic = id
      this.toggleEachTag = {}
      this.idEachTag = []
      this.getTopicById(id)
      this.is_show_sidebar = false
    },
    getTopicById(id) {
      this.is_loading_select_topic = true
      userManualApi.getTopicById(id).then(resp => {
        this.content_list = resp
        this.renderContent = false
        const idList = this.content_list.map((res) => res._id)
        idList.forEach((item) => {
          this.toggleEachTag[`collapse_${item}`] = true
          this.idEachTag.push(item)
        })
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading_select_topic = false
        this.renderContent = true
      })
    },
    checkNameTopic(data) {
      return this.$i18n.locale === 'th' ? data?.nameTh : data?.nameEn
    },
    changeNameTopicContent(name) {
      return this.$i18n.locale === 'th' ? name?.th : name?.en
    },
    changeWordingContent(content) {
      return this.$i18n.locale === 'th' ? content?.th : content?.en
    }
  }
}
</script>
<style lang="scss">
#client-manual-setting {
  .collapse-container {
    position: relative;
    padding: 8px 12px;

    .collapse-container__bottom-right {
      border-radius: 50%;
      padding: 12px 18px;
      position: absolute;
      right: 0;
      bottom: 0;

      .arrow-icon {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}


#content-user-manual {
  .ql-indent-1, .ql-indent-2, .ql-indent-3, .ql-indent-4, .ql-indent-5 {
    font-weight: 400 !important;
  }

  .ql-indent-1 {
    padding-left: 3rem;
  }

  .ql-indent-2 {
    padding-left: 6rem;
  }

  .ql-indent-3 {
    padding-left: 9rem;
  }

  .ql-indent-4 {
    padding-left: 12rem;
  }

  .ql-indent-5 {
    padding-left: 15rem;
  }
}
</style>

<style scoped>
.caret {
  position: relative;
  width: 30px;

  .default-icon {
    display: flex;
    height: 12px;
    width: 12px;
  }

  .ab-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    z-index: 1;
  }

  .default-caret {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in;
  }

  .normal-caret {
    transform: rotate(180deg) !important;
    transition: transform 0.2s ease-in !important;
  }

}

.user-manual {
  min-height: 80vh;
}

.user-manual::v-deep .card-body {
  padding: 0;
  display: flex;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  position: absolute;
  border-right: 1px solid #F4F4F4;
  height: 100%;
  border-radius: 10px 0 0 10px;
}

.scroll-bar-box {
  overflow: auto;
  min-height: 74vh;
  max-height: 74vh;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.active-topic {
  color: var(--primary-color);
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  margin-left: 200px;
  padding: 1.5rem;
  min-height: 80vh;
  max-height: 80vh;
}

div.content-loading {
  margin-left: 200px;
  padding: 1.5rem;
  min-height: 89vh;
  transform: translate(0%, 50%);
}

div.content-full-box {
  margin-left: 0px;
  padding: 1.5rem;
  min-height: 89vh;
}

.list-menu {
  padding: .8rem;
  margin-left: .7rem;
}

.list-menu:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #F4F4F4;
}

.title-list {
  font-size: 16px;
  font-weight: bold;
  padding: .8rem;
  color: #0c0d10;
  margin-left: .7rem;
  margin-top: .6rem;
}

.list-menu:active {
  color: var(--primary-color);
  cursor: pointer;
}

.content {
  width: 100%;
  overflow: auto;
  margin: 1rem;
  height: 70vh;
  display: block;

  .title {
    background-color: rgba(var(--primary-color-rgb), 0.08);
    color: var(--secondary-color);
    padding: 8px 12px;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

  }

}

.content::v-deep img {
  width: 100% !important; /* or any custom size */
  height: 100% !important;
  object-fit: contain !important;
}

.sidebar-mini::v-deep .card-body {
  padding: 0;
  border-radius: 10px;
}

.sidebar-mini-shadow::v-deep .card-body {
  border-radius: 14px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sidebar-mini-bar::v-deep .card-body {
  padding: 0;
  background: #FFFFFF;
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: 14px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.list-menu-mini {
  padding: .8rem;
  width: 100%;
}

.list-menu-mini:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.content::v-deep .empty-file-icon {
  width: 80px !important;
  height: 80px !important;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>