import service from "./index";
import functionsCommon from "@/common/functions";


const resourcePath = '/manual'

export default {
    getListUserManualModule () {
        return service.apiAuth.get(`${resourcePath}/list-topics`).then(resp => {
            return resp?.data?.data
        }).catch(err => {
            throw err
        })
    },
    getTopicById(id) {
        return service.apiAuth.get(`${resourcePath}/list-paragraphs?topic_id=${id}`).then(resp => {
            return resp?.data?.data
        }).catch(err => {
            throw err
        })
    }
}